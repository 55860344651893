import Siema from "siema";

const heroAlertContent = document.querySelector(".hero-alert__content");

const alertButtons = [...document.querySelectorAll(".alert-slide")];

// Create Initial Hero Alert Slider for Close Button To Destroy
const heroAlertContentEl = document.querySelector(".hero-alert__content");

let heroAlertSlider = null;

if (heroAlertContentEl) {
    heroAlertSlider = new Siema({ selector: ".hero-alert__content" });
}

// HERO ALERT

const alert = () => {
    const heroAlert = document.querySelector(".hero-alert");
    const heroAlertIcon = document.querySelector(".hero-alert__alertIcon");
    const heroAlertCloseButton = document.querySelector(
        ".hero-alert__closeButton"
    );

    // Guard clause
    if (!heroAlert) return;

    // Check Window Width before making/destroying Testimonial Slider
    window.addEventListener("load", e => {
        heroAlertSlider.destroy(true);
    });

    // Toggle Open/Close Hero Alert When Clicking Hero Alert Icon
    heroAlertIcon.addEventListener("click", e => {
        if (heroAlert.classList.contains("-open")) {
            // Remove Open Class and Add Closed Animation for .6s
            heroAlert.classList.remove("-open");
            heroAlert.classList.add("-closed");

            setTimeout(function() {
                heroAlert.classList.remove("-closed");
            }, 600);
        } else {
            heroAlert.classList.add("-open");
        }
    });

    // Close Hero Alert
    heroAlertCloseButton.addEventListener("click", e => {
        if (heroAlert.classList.contains("-open")) {
            // Remove Open Class and Add Closed Animation for .6s
            heroAlert.classList.remove("-open");
            heroAlert.classList.add("-closed");

            setTimeout(function() {
                heroAlert.classList.remove("-closed");
            }, 600);
        }
    });

    const options = {
        attributes: true
    };

    const heroAlertWatcher = function(mutationList, observer) {
        mutationList.forEach(function(mutation) {
            if (
                mutation.type === "attributes" &&
                mutation.attributeName === "class"
            ) {
                if (mutation.target.classList.contains("-open")) {
                    const heroAlertSliderNode = document.querySelector(
                        ".hero-alert__content"
                    );
                    // Create A New  Hero Alert Slider
                    const heroAlertSlider = new Siema({
                        selector: ".hero-alert__content",
                        duration: 750,
                        loop: true,
                        easing: "linear",
                        perPage: 1,
                        onChange: () => {
                            const currentIndex = heroAlertSlider.currentSlide;

                            alertButtons.forEach(button =>
                                button.classList.remove("-active")
                            );

                            const correspondingButton = document.querySelector(
                                `.alert-slide[data-slide="${currentIndex}"]`
                            );

                            correspondingButton.classList.add("-active");
                        }
                    });

                    let heroAlertSliderInterval = setInterval(() => { heroAlertSlider.next() }, 6000);

                    heroAlertSliderNode.addEventListener("mouseenter", e => {
                        clearInterval(heroAlertSliderInterval);
                    });

                    heroAlertSliderNode.addEventListener("mouseleave", e => {
                        heroAlertSliderInterval = setInterval(() => {
                            heroAlertSlider.next()
                        }, 6000);
                    });

                    alertButtons.forEach(button => {
                        button.addEventListener("click", () => {
                            const navIndex = button.dataset.slide * 1;

                            heroAlertSlider.goTo(navIndex);
                            clearInterval(heroAlertSliderInterval);
                            heroAlertSliderInterval = setInterval(() => { heroAlertSlider.next() }, 6000);
                        });
                    });
                } else {
                    heroAlertSlider.destroy(true);
                }
            }
        });
    };

    const heroAlertObserver = new MutationObserver(heroAlertWatcher);

    heroAlertObserver.observe(heroAlert, options);
};

alert();
